<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>FR No</th>
          <th>FR Date</th>
          <th>Party</th>
          <th>Amount</th>
          <th>Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in requisitionList.data" :key="i">
          <td>{{ item.fr_number }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.total_amount }}</td>
          <td>{{ item.fr_type }}</td>
          <td>{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!requisitionList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const props  = defineProps({
  requisitionList: Object,
});

const goToPrint = (item) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
    requisitionId: item.id,
  }
  router.push({
    name: item.fr_type === "from_pr" ? 'fund-requisition-print' : 'billing-fund-requisition-print',
    params: param,
    query: route.query
  })
}

const goToEdit = (item) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.requisitionId = item.id;

  router.push({
    name: item.fr_type === "from_pr" ? 'fund-requisition-add' : 'billing-fund-requisition-add',
    params: param,
    query: query
  })
}

</script>
