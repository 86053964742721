<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2 ">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Fund Requisition List"
          @onClickAddNewButton="onClickAddNewButton"
          :showExtraBtn="true"
          extra-btn-title="Fund Requisition from PR"
          @onClickExtraBtn="onClickAddNewFromPRButton"
      />

      <DateQuerySetter @onClickGo="getRequisitions" class="mt-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requester</label>
          <input readonly class="form-control" type="text" name="Requester" v-model="user" >
        </div>
      </DateQuerySetter>
    </div>

    <ListTable :requisition-list="requisitionList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/requisition/FundRequisitionListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {useStore} from "vuex";

const {fetchFundRequisitionList} = handleRequisitions();
const router = useRouter()
const route = useRoute()
const offset = ref(20);
const store = useStore()

let page = ref(1);
let loading = ref(false);
let requisitionList = ref({});
const user = computed(() => {
  return store.state.user.user.name;
})
let companyId = computed(() => route.params.companyId);

function onClickAddNewButton() {
  console.log('Button clicked'); return;
  router.push({name: 'billing-fund-requisition-add', params: route.params, query: route.query})
}

function onClickAddNewFromPRButton() {
  router.push({name: 'pending-fr-from-pr', params: route.params, query: route.query})
}

async function getRequisitions() {
  try {
    const res = await fetchFundRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
    }else{
      requisitionList.value = [];
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if (page.value) query += '&page=' + page.value
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
}

onMounted(async () => {
  await getRequisitions();
})
</script>
